<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>Statement of Accounts</h3>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <v-switch
                v-model="allCustomers"
                label="All Customer"
                dense
                @click="clearData()"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                prepend-inner-icon="mdi-account"
                v-model="customer"
                :items="customers"
                item-text="clastname"
                item-value="custledgkey"
                label="Customer"
                persistent-hint
                return-object
                single-line
                dense
                outlined
                :disabled="allCustomers"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getOutstandings"
                :disabled="!valid"
                >Generate</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                label="Sales"
                v-model="sales"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Receipts"
                v-model="receipts"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Credit Notes"
                v-model="credits"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Balance"
                v-model="balance"
                readonly
                outlined
                required
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="this.allCustomers"
          :loading="loading"
          :search="search"
          :headers="adminHeaders"
          :items="invoices"
          item-key="invkey"
          class="elevation-1"
          loading-text="Retrieving data... please wait"
        >
        </v-data-table>
        <v-data-table
          v-if="!this.allCustomers"
          :loading="loading"
          :search="search"
          :headers="clientHeaders"
          :items="invoices"
          item-key="invkey"
          class="elevation-1"
          loading-text="Retrieving data... please wait"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";

export default {
  data() {
    return {
      valid: true,
      search: "",
      loading: false,
      adminHeaders: [
        { text: "Inv No.", align: "start", value: "invoicenum" },
        { text: "Inv Date", align: "start", value: "invoicedate" },
        { text: "Customer Name", align: "start", value: "customer" },
        { text: "Passenger", align: "start", value: "passenger" },
        { text: "Sales (GBP)", align: "end", value: "sales" },
        { text: "Receipts (GBP)", align: "end", value: "receipts" },
        { text: "Credits (GBP)", align: "end", value: "credits" },
        { text: "Balance (GBP)", align: "end", value: "balance" },
      ],

      clientHeaders: [
        { text: "Inv No.", align: "start", value: "invoicenum" },
        { text: "Inv Date", align: "start", value: "invoicedate" },
        { text: "Passenger", align: "start", value: "passenger" },
        { text: "Sales (GBP)", align: "end", value: "sales" },
        { text: "Receipts (GBP)", align: "end", value: "receipts" },
        { text: "Credits (GBP)", align: "end", value: "credits" },
        { text: "Balance (GBP)", align: "end", value: "balance" },
      ],

      allCustomers: true,
      invoices: [],
      pdfdata: [],
      customers: [],
      customer: {},
      selectedItem: {},
      selectedIndex: -1,
      sales: 0,
      receipts: 0,
      credits: 0,
      balance: 0,

      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
    };
  },

  mounted() {
    this.getCustomers();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },
    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    clearData() {
      this.invoices = [];
      this.pdfdata = [];
      this.selectedItem = {};
      this.selectedIndex = -1;
      this.customer = {};
    },

    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else if (
        !this.allCustomers &&
        Object.keys(this.customer).length === 0
      ) {
        this.$toasted.global.optimus_error({
          message: "Select a customer from the drop down list.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("DD-MM-YYYY");
    },

    async getOutstandings() {
      if (this.validateData()) {
        this.invoices = [];
        this.pdfdata = [];
        this.sales = 0;
        this.receipts = 0;
        this.credits = 0;
        this.balance = 0;

        try {
          let response = "";
          this.loading = true;
          if (!this.allCustomers) {
            response = await api.post("/aejmis/api/outstandings", {
              startdate: this.startDate,
              enddate: this.endDate,
              ledgerkey: this.customer.custledgkey,
            });
          } else {
            response = await api.post("/aejmis/api/alloutstandings", {
              startdate: this.startDate,
              enddate: this.endDate,
            });
          }

          this.pdfdata = response.data;
          this.pdfdata.map(
            ({
              invkey,
              invoicenum,
              invoicedate,
              firstname,
              lastname,
              costing,
              receipt,
              creditnote,
              customer,
            }) => {
              const { invamt, rcptamt, credamt, balance } = this.calcDues(
                costing,
                receipt,
                creditnote
              );
              this.invoices.push({
                customer: customer.clastname,
                invkey: invkey,
                invoicenum: invoicenum,
                invoicedate: this.dateFormat(invoicedate),
                passenger: `${firstname.trim()} ${lastname.trim()}`,
                sales: invamt,
                receipts: rcptamt,
                credits: credamt,
                balance: balance,
              });
            }
          );
          this.sales = this.sales.toFixed(2);
          this.receipts = this.receipts.toFixed(2);
          this.credits = this.credits.toFixed(2);
          this.balance = this.balance.toFixed(2);
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },

    calcDues(costing, receipt, creditnote) {
      let invamt = 0;
      let rcptamt = 0;
      let credamt = 0;
      let balance = 0;
      costing.forEach((item) => {
        invamt = invamt + item.quantity * item.gross;
      });
      receipt.forEach((item) => {
        rcptamt =
          rcptamt +
          (item.amountpaidtype1 +
            item.amountpaidtype2 +
            item.amountpaidtype3 +
            item.amountpaidtype4 +
            item.amountpaidtype5 +
            item.amountpaidtype6 +
            item.amountpaidtype7 +
            item.amountpaidtype8);
      });

      if (creditnote != null) {
        credamt = credamt + creditnote.creditnotevalue;
      }
      this.sales = this.sales + invamt;
      this.receipts = this.receipts + rcptamt;
      this.credits = this.credits + credamt;

      balance = invamt - rcptamt - credamt;
      this.balance = this.balance + balance;

      invamt = invamt.toFixed(2);
      rcptamt = rcptamt.toFixed(2);
      credamt = credamt.toFixed(2);
      balance = balance.toFixed(2);
      return { invamt, rcptamt, credamt, balance };
    },

    async getCustomers() {
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/customers");
        this.customers = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
